.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;

  @media (--viewport-m) {
    flex-direction: row;
  }
}
